import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Stack,
} from '@mui/material';
import { Form as AppForm, Formik } from 'formik';
import * as yup from 'yup';
import { format } from 'date-fns';

import LicenseReviewFormRow from './LicenseReviewFormRow';

const formatDate = (lastLogin) => {
  if (lastLogin === 'None Recorded') return 'Unknown';
  return lastLogin ? format(new Date(lastLogin), 'MM/dd/yyyy') : 'Unknown';
};

const validationSchema = yup.object().shape({
  values: yup.array(
    yup
      .object({
        participantID: yup.number().required(),
        participantApplicationID: yup.number().required(),
        keepApplication: yup.boolean().required(),
        reason: yup.string().when('keepApplication', {
          is: true,
          then: (schema) => schema.required().min(15),
          otherwise: (schema) => schema.notRequired().nullable(),
        }),
      })
      .required()
  ),
});

// Total the estimated monthly savings for the user
const getNumbers = (applications, items) => {
  if (!applications || applications.length <= 0 || !items) return null;
  const j = [];
  applications.forEach((app) => {
    items
      .filter((item) => item.keepApplication === 'false')
      .forEach((item) => {
        if (item.participantApplicationID === app.participantApplicationID) {
          j.push(app.monthlyCost);
        }
      });
  });
  const saved = j.reduce((a, b) => a + b, 0);
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(saved);
};

const LicenseReviewForm = (props) => {
  const { applications, participantID, submitLicenseReview } = props;
  const navigate = useNavigate();
  const [submissionError, setsubmissionError] = useState();

  const handleFormSubmit = async (values) => {
    // update keepApplication value to bool
    // formik stores everything as strings, so just manipulating it before we submit
    const formValues = values.values.map((app) => {
      return { ...app, keepApplication: app.keepApplication === 'true' };
    });
    const submitResp = await submitLicenseReview(formValues);
    if (submitResp.error) {
      setsubmissionError(true);
      return;
    }
    // setNumberSaved(getNumbers(applications, values.values));
    navigate('/submitted', { state: { numberSaved: getNumbers(applications, values.values) } });
  };

  // Create an array of objects with the user's application data
  const defaultValues = applications.map((app) => ({
    participantApplicationID: app.participantApplicationID,
    participantID,
    keepApplication: '',
    reason: '',
  }));

  return (
    <Formik
      initialValues={{ values: defaultValues }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, isSubmitting, errors, touched, values }) => (
        <AppForm onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
          <FormControl
            variant="standard"
            component={Paper}
            square
            elevate={6}
            sx={{ marginTop: '1rem', marginBottom: '1rem', padding: '1rem' }}
          >
            <FormLabel sx={{ visibility: 'hidden' }} component="legend">
              Review Applications
            </FormLabel>
            <Table sx={{ marginBottom: '15px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold' }}>Keep</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold' }}>Remove</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold' }}>Application</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold' }}>Cost</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold' }}>Last Login</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications
                  .sort((app) => (app.lastLogin === 'None Recorded' ? -1 : 1))
                  .map((app) => {
                    return (
                      <LicenseReviewFormRow
                        key={app.participantApplicationID}
                        pid={app.participantApplicationID}
                        appName={app.name}
                        appCost={app.monthlyCost}
                        appLoginCount={app.loginCount}
                        appLastLogin={formatDate(app.lastLogin)}
                      />
                    );
                  })}
              </TableBody>
            </Table>
            <Stack spacing={2}>
              {errors?.values?.length > 0 && touched?.values?.length === applications.length && (
                <>
                  {console.log(errors)}
                  <Alert severity="error">You have to fill all values.</Alert>
                </>
              )}
              {isSubmitting && (
                <Alert severity="success">
                  Thanks for your submission, we will see you again soon.
                </Alert>
              )}
              {submissionError && <Alert severity="error">Oh no! Trouble submitting form!</Alert>}
              <Typography
                sx={{
                  p: 2,
                  textAlign: 'right',
                  fontWeight: '600',
                }}
              >
                Your current savings:{' '}
                <span style={{ fontWeight: 600, color: 'green' }}>
                  {getNumbers(applications, values?.values)}
                </span>
              </Typography>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                Submit
              </Button>
            </Stack>
          </FormControl>
        </AppForm>
      )}
    </Formik>
  );
};

LicenseReviewForm.propTypes = {
  applications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  submitLicenseReview: PropTypes.func.isRequired,
  participantID: PropTypes.number.isRequired,
};

export default LicenseReviewForm;
