import React, { useEffect, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useAPI } from './APIProvider';

export const UserContext = React.createContext();
export const useUser = () => useContext(UserContext);

const defaultUserAccess = {
  licenses: true,
  consumption: false,
  employeeType: 'user',
  isPresident: false,
  isTechLead: false,
  hasError: false,
};

const UserProvider = ({ children = null }) => {
  const { isAuthenticated, user } = useAuth0();
  const [userAccess, setUserAccess] = useState(defaultUserAccess);

  const { getUserAccess } = useAPI();

  useEffect(() => {
    const getAccessData = async () => {
      let accessData = defaultUserAccess;

      accessData = await getUserAccess();
      console.debug('loaded user access');

      const consumption = accessData.access?.consumption ?? defaultUserAccess.consumption;
      const employeeType = accessData.access?.employeeType ?? defaultUserAccess.employeeType;

      setUserAccess({
        ...accessData.access,
        ...accessData.cycle,
        isPresident: consumption && employeeType === 'president',
        isTechLead: consumption && employeeType === 'itlead',
      });
    };
    // if not authed, keep it moving
    if (!isAuthenticated) return;
    getAccessData();
  }, [isAuthenticated, getUserAccess, user]);
  return <UserContext.Provider value={{ userAccess }}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: null,
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserProvider;
