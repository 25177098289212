/* eslint-disable no-console */
import axios from 'axios';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

const API_BASE_URL = 'https://licenses.identity.rvapps.io';

export const APIContext = React.createContext();
export const useAPI = () => useContext(APIContext);

const APIProvider = ({ children = null }) => {
  const { getAccessTokenSilently } = useAuth0();
  const request = async ({
    method = 'GET',
    route,
    body = null,
    isAuth = true,
    headers = {},
    paramString = '',
  }) => {
    const token = await getAccessTokenSilently();
    if (isAuth) {
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = `Bearer ${token}`;
    }

    const requestOptions = {
      method,
      url: API_BASE_URL + route + paramString,
      headers,
    };

    // since we pass in 'body' as a pre-serialized json string, axios needs
    // us to set the content-type otherwise it'll try to form-encode the json
    // string we pass in for the body
    if (body !== '') {
      requestOptions.data = body;
      requestOptions.headers['Content-Type'] = 'application/json';
    }

    let response;
    try {
      const apiResponse = await axios(requestOptions);

      // handle errors if we can
      if (apiResponse.status >= 300 && apiResponse.data && apiResponse.data.message) {
        response = {
          error:
            apiResponse.data?.message ||
            apiResponse.data ||
            `received status ${apiResponse.status}`,
        };
      }

      // success
      if (apiResponse.data) {
        response = apiResponse.data;
      }
    } catch (err) {
      // log and return error message
      console.error('requestError', { err, route });
      response = { error: err.message };
    }

    return response;
  };

  const getUserApplications = async () => {
    const formData = await request({
      method: 'GET',
      route: '/user/application',
    });

    if (formData && Object.keys(formData).length === 0) {
      return { error: 'Empty API response' };
    }

    return formData;
  };

  const getManagers = async (paramString = '') => {
    const formData = await request({
      method: 'GET',
      route: '/manager',
      paramString,
    });
    return formData;
  };

  const getVerticals = async (paramString = '') => {
    const formData = await request({
      method: 'GET',
      route: '/vertical',
      paramString,
    });
    return formData;
  };

  const getCycles = async (paramString = '') => {
    const formData = await request({
      method: 'GET',
      route: '/cycle',
      paramString,
    });
    return formData;
  };

  const submitLicenseReview = async (formInput) => {
    const response = await request({
      method: 'POST',
      route: '/submit-license-review',
      body: formInput,
    });
    return response;
  };

  const getReportByCycle = async (
    cycleId,
    verticalId = null,
    employeeId = null,
    download = false
  ) => {
    const vertical = verticalId ? `&verticalId=${verticalId}` : '';
    const employee = employeeId ? `&employeeId=${employeeId}` : '';
    const paramString = `?cycleId=${cycleId}${vertical}${employee}`;
    const routeURL = download ? '/reporting/user/download' : '/reporting/user';
    const formData = await request({
      method: 'GET',
      route: routeURL,
      paramString,
    });
    return formData;
  };

  const getPendingUsers = async (cycleId, verticalId = null, employeeId = null) => {
    const vertical = verticalId ? `&verticalId=${verticalId}` : '';
    const employee = employeeId ? `&employeeId=${employeeId}` : '';
    const paramString = `?cycleId=${cycleId}${vertical}${employee}`;
    const routeURL = '/reporting/user/pending/download';
    const data = await request({
      method: 'GET',
      route: routeURL,
      paramString,
    });
    return data;
  };

  const getUserAccess = async () => {
    const accessData = await request({
      method: 'GET',
      route: '/user/access',
    });
    return accessData;
  };

  const getTeamLicenses = async () => {
    const TeamLicenses = await request({
      method: 'GET',
      route: '/consumption',
    });
    return TeamLicenses;
  };

  const getConsumptionDownload = async () => {
    const consumptionReport = await request({
      method: 'GET',
      route: '/consumption/reporting/download',
    });
    return consumptionReport;
  };

  const saveSupplierReview = async (formInput, isDone) => {
    try {
      const bodyData = {
        actionId: formInput.values.actionId === '' ? null : formInput.values.actionId,
        techLeadNotes: formInput.values.teamLeadNotes,
        isComplete: isDone,
      };
      const response = await request({
        method: 'PUT',
        route: `/consumption-charge/${formInput.values.allocationId}/tech-lead`,
        body: bodyData,
      });
      return response.message === 'success';
    } catch (error) {
      console.log(error.message);
    }
    return false;
  };

  const sendPresidentNotes = async (formInput) => {
    try {
      const bodyData = {
        presidentNotes: formInput.values.presidentNotes,
      };
      const response = await request({
        method: 'PUT',
        route: `/consumption-charge/${formInput.values.allocationId}/president-notes`,
        body: bodyData,
      });
      return response.message === 'success';
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  const sendPresidentConfirmation = async () => {
    const response = await request({
      method: 'POST',
      route: '/consumption/president/submit',
    });
    return response.message === 'success';
  };

  // Fetches table data for /status page
  const getCycleStatus = async () => {
    const CycleStatus = await request({
      method: 'GET',
      route: '/consumption/cycle/status',
    });
    return CycleStatus;
  };

  // Csv download for /status page data
  const getConsumptionCycleStatusDownload = async () => {
    const routeURL = '/consumption-charge/cycle/reporting/download';
    const data = await request({
      method: 'GET',
      route: routeURL,
    });
    return data;
  };

  // Send information for manager override form
  const sendManagerOverride = async (formInput) => {
    const routeURL = '/manager/override/submit';
    const response = await request({
      method: 'POST',
      route: routeURL,
      body: formInput,
    });
    return response;
  };

  return (
    <APIContext.Provider
      value={{
        getUserApplications,
        getManagers,
        getVerticals,
        getCycles,
        getConsumptionDownload,
        submitLicenseReview,
        getConsumptionCycleStatusDownload,
        getReportByCycle,
        getUserAccess,
        getTeamLicenses,
        saveSupplierReview,
        sendPresidentNotes,
        sendPresidentConfirmation,
        getPendingUsers,
        getCycleStatus,
        sendManagerOverride,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

APIProvider.defaultProps = {
  children: null,
};

APIProvider.propTypes = {
  children: PropTypes.element,
};

export default APIProvider;
